import React from "react";
import * as PR from "../../prime-modules/index";
import { logo, logoIcon } from "../../assets/images";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { languageGetApi } from "../../services/api";
import { setLanguageIntial } from "../../store/language.slice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const HeaderMenu = () => {
    
    const shopDomainName = process.env.REACT_APP_SHOP_URL;
    const { i18n } = useTranslation();
    const language = i18n.language.split('-')[0];
    const dispatch = useDispatch();
    const storeLanguage = useSelector(state => state.language);
    const { branding } = storeLanguage.activelang;
    const languages = [
        { name: 'English', code: 'EN' },
        { name: 'Spanish', code: 'ES' },
        { name: 'Portuguese', code: 'PT' }
    ];

    const onSuccess = v => dispatch(setLanguageIntial({k:language,v}));
    const enabled = true

    useQuery(["BRANDING", language],languageGetApi.bind(null, language, "branding"),
        {
          enabled,
          onSuccess,
        }
      );
      useQuery(["VALIDATIONS", language],languageGetApi.bind(null, language, "validations-new"),
        {
          enabled,
          onSuccess,
        }
      );
    
    const languageChangeHandler = e => {
        const ln = e.value.code.toLocaleLowerCase();
        i18n.changeLanguage(ln);
    };
    
    return (
    <>
        <section className="header-menu-section">
            <div className="grid grid-nogutter">
                <div className="col-12">
                    <div className="flex align-items-center justify-content-between">
                        <div className="header-left">
                            <Link to="/"><PR.Image src={logo} alt="data2go logo" className="logo-img hide-on-mobile" /></Link>
                            <Link to="/"><PR.Image src={logoIcon} alt="data2go logo" className="logo-img show-on-mobile" /></Link>
                        </div>
                        <div className="header-right flex align-items-center gap-3">
                            <div className="card">
                                <PR.Dropdown value={languages.filter(el=>el.code.toLocaleLowerCase()===language)?.[0]} onChange={languageChangeHandler} options={languages} optionLabel="name" 
                                    placeholder="English" className="language-dropdown" panelClassName="language-dropdown-panel" />
                            </div>
                            <Link to={shopDomainName} target="_blank">
                                <PR.Button label={branding?.BRAND0001} className="view-button" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
};

export default HeaderMenu;