import { useSelector } from 'react-redux';
import './index.scss'
import * as PR from "../../prime-modules/index";
import HeaderMenu from '../../components/layout/HeaderMenu';
import { useMutation } from 'react-query'
import { contactPostApi } from '../../services/api';
import { contactFormValidation } from '../../utils/schema';
import { Formik, Form } from 'formik';
import Input from '../../components/form/Input';
import { GET_IN_TOUCH } from '../../services/interface';
import { useRef } from 'react';

const GetInTouch = () => {
    const {branding} = useSelector(state=>state.language?.activelang);
    const toast = useRef();
    const formikRef = useRef();

    const { mutate } = useMutation( contactPostApi, {
        onSuccess:()=>{
            toast.current.show({severity: "success", summary: 'Success',detail: 'We received your request and we will get back to you soon.'});
            formikRef.current.resetForm();
        },
        onError: () =>{
            toast.current.show({severity: "error", summary: 'Error',detail: 'Error'});
        }
    });

    const submitHandler = ({email}) => {
        mutate({...GET_IN_TOUCH, firstName:email.split('@')?.[0],  email});
    }

    return (
        <>
            <div className='main-section'>
                <HeaderMenu />
                <PR.Toast ref={toast} />
            </div>
            <div className="getintouch-section">
                <div className='container'>
                    <div className='grid custom-margin'>
                        <div className="col-12 text-center">
                            <h1>{branding?.BRAND0051}</h1>
                            <Formik innerRef={formikRef} validationSchema={contactFormValidation} initialValues={{email:''}} onSubmit={submitHandler} >
                                <Form autoComplete="off" className="form-block" >
                                    <Input name="email" id="email" aria-describedby="email" placeholder={branding?.BRAND0052} />
                                    <PR.Button label={`${branding?.BRAND0053}`} type='submit' className="goto-button"/>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GetInTouch