import { useField } from "formik";
import { useSelector } from "react-redux";

const withValidations = WrappedComponent => {

    return props => {

        const [field, meta] = useField(props);
        const { validations } = useSelector(state => state.language.activelang);

        return <div className="flex flex-column gap-2 mb-4">
            <label htmlFor={props?.name}>{props?.label}</label>
            <WrappedComponent {...field} {...props} />
            {meta.touched && meta.error && ( <span className="err-msg">{validations[meta.error] ?? 'Error'}</span>)}
        </div>
    }
}

export default withValidations;