import * as Yup from 'yup'

const emailSchema =  Yup.string()
      .trim()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/,
        'VLDN0006'
      )
      .email('VLDN0006')
      .required('VLDN0005');

export const contactFormValidation =  Yup.object().shape({
    email : emailSchema
})

export const questionFormValidation =  Yup.object().shape({
    email : emailSchema,
    body: Yup.string().trim().required('VLDN0031')
})
export const caseStudiyFormValidation =  Yup.object().shape({
  email : emailSchema,
  body: Yup.string().trim().required('VLDN0030'),
})