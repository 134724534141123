import axios from 'axios';

// Base Config //
const client = axios.create({ baseURL: '/v1/' });
const api = data => client(data).then(r => r?.data).catch(e => { throw new Error(e.response?.data?.errorMsg ?? e) });


// Api Functions //

export const contactPostApi = data => api({ method: 'post', url: 'contact', data });

export const languageGetApi = (ln,screen) => api({method:'get', url:`assets?language=${ln}&screen=${screen}`});