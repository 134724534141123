import React from 'react';
import * as PR from "../../prime-modules/index";
import { Link } from "react-router-dom";

const Footer = ({branding, footerRef }) => { 
    const shopDomainName = process.env.REACT_APP_SHOP_URL;
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    };
    return (
    <>
        <footer className="footer-section footer-animation" ref={footerRef}>
            <div className="grid grid-nogutter">
                <div className="col-12 text-center">
                    <h2>{branding?.BRAND0002} <br/> {branding?.BRAND0003}</h2>
                </div>
            </div>
            <svg width="100%" height="100%" viewBox="0 0 1920 669" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="654.581" cy="301.373" rx="144.518" ry="146.588" fill="#F73859"/>
                <ellipse cx="1178.64" cy="537.49" rx="129.611" ry="131.681" fill="#6A459A"/>

                {/* Questions? */}
                <Link to={'/questions'} className='footer-text'>
                    <rect className='rectangle' x="1163.43" width="261.706" height="760.272" rx="130.853" transform="rotate(45 1163.43 0)" fill="white"/>
                    <text x="800" y="140" fill="black" fontSize="24" textAnchor="middle" transform="rotate(-45 1163.43 0)" alignmentBaseline="middle">
                        {branding?.BRAND0006}?
                    </text>
                </Link>

                {/* Visit ourweb shop */}
                <Link to={shopDomainName}  className='footer-text' target='_blank'>
                    <path className='rectangle triangle' d="M1550.71 588.128C1537.84 636.162 1477.8 652.25 1442.64 617.087L1236.73 411.182C1201.57 376.019 1217.66 315.977 1265.69 303.107L1546.96 227.74C1595 214.87 1638.95 258.823 1626.08 306.856L1550.71 588.128Z" fill="white"/>
                    <text className='triangle-text' x="1200" y="410" fill="black" fontSize="24" textAnchor="middle" transform="rotate(-45 1250 0)" alignmentBaseline="middle">
                        <tspan x="1120" dy="0">{branding?.BRAND0007}</tspan>
                        <tspan x="1120" dy="62">{branding?.BRAND0008}</tspan>
                    </text>
                </Link>

                {/* Send us an email! */}
                <Link to={'/get-in-touch'} className='footer-text email'>
                    <rect className='rectangle' x="662.271" y="419.548" width="261.706" height="760.272" rx="130.853" transform="rotate(90 662.271 419.548)" fill="white"/>
                    <text x="200" y="550" fill="black" fontSize="24" textAnchor="middle" alignmentBaseline="middle">
                        <tspan x="250" dy="0">{branding?.BRAND0004}</tspan>
                        <tspan x="160" dy="72">{branding?.BRAND0005}</tspan>
                    </text>
                </Link>

                {/* back to top */}
                <Link className='footer-text back-to-top' onClick={scrollToTop}>
                    <rect className='rectangle' x="2307.87" y="512.304" width="200.42" height="760.272" rx="100.21" transform="rotate(90 2307.87 512.304)" fill="#F73859"/>
                    <text x="1760" y="600" fill="black" fontSize="24" textAnchor="middle" alignmentBaseline="middle">
                        {branding?.BRAND0009}
                    </text>
                </Link>
            </svg>
        </footer>

        {/* <Simulation branding={branding}/> */}
        <div className='mobile-footer'>
            <div className="grid">
                <div className="col-12 md:col-6">
                    <div className='box'>
                        <Link to={"/get-in-touch"}><h3>{branding?.BRAND0004} {branding?.BRAND0005}</h3></Link>
                    </div>
                </div>
                <div className="col-12 md:col-6">
                    <div className='box'>
                        <Link to={"/questions"}><h3>{branding?.BRAND0006}?</h3></Link>
                    </div>
                </div>
                <div className="col-12">
                    <div className='box last-box'>
                        <Link to={shopDomainName} target='_blank'><h3>{branding?.BRAND0007} {branding?.BRAND0008}</h3></Link>
                    </div>
                </div>
            </div>
            <PR.ScrollTop className="w-2rem h-2rem border-round scroll-to-top" icon="pi pi-arrow-up text-base" />
        </div>
    </>
  );
};

export default Footer;