export const GET_IN_TOUCH = {
    firstName: '',
    lastName: '',
    email: '',
    body: 'GET IN TOUCH',
    phone: {
        localPhoneNumber: '',
        callingCode: ''
    }
}
export const PARTNER_PROGRAM = {
    firstName: '',
    lastName: '',
    email: '',
    body: 'PARTNER PROGRAM',
    phone: {
        localPhoneNumber: '',
        callingCode: ''
    }
}

export const CASE_STUDY = {
    firstName: '',
    lastName: '',
    email: '',
    body: 'CASE STUDY: ',
    phone: {
        localPhoneNumber: '',
        callingCode: ''
    }
}