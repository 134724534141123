import React from 'react';
import { useSelector } from 'react-redux';
import "./JoinOurTeam.scss";
import * as PR from "../../prime-modules/index";
import { liftAtBH1, liftAtBH2, liftAtBH3, userAvator, map, teamMumber1, teamMumber2, teamMumber3, teamMumber4, teamMumber5, teamMumber6  } from "../../assets/images";
import Layout from "../../components/layout/Layout";
import { Link } from "react-router-dom";

let jobList = [{
    title:"JOB0001" ,
    description:"JOB0002",
    location:"JOB0003"
},
{
    title:"JOB0001" ,
    description:"JOB0002",
    location:"JOB0003"
},
{
    title:"JOB0001" ,
    description:"JOB0002",
    location:"JOB0003"
},]

const JoinOurTeam = () => {
    const {branding, job} = useSelector(state=>state.language?.activelang);

const OptingOptionSection = ()=>{
          const element = document.getElementById("positions");
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }      
}
    return (
    <>
        <Layout>
            {/*********** Header Section ***********/}
            <section className="header-banner-section">
                <div className="header-text">
                    <h1>{branding?.BRAND0059}</h1>
                    <PR.Button label={branding?.BRAND0060} onClick={OptingOptionSection} className="open-button" />
                </div>
            </section>

            {/*********** Life-at-Bluehouse Section ***********/}
            <section className="life-at-bluehouse-section">
                <div className="life-at-bluehouse-container">
                    <h2>{branding?.BRAND0061}</h2>
                    <div className="flex gap-5 mobile-flex">
                        <div className="left">
                            <PR.Image src={liftAtBH1} alt="Life at Bluehouse"/>
                        </div>
                        <div className="right">
                            <PR.Image src={liftAtBH2} alt="Life at Bluehouse"/>
                            <PR.Image src={liftAtBH3} alt="Life at Bluehouse"/>
                        </div>
                    </div>
                </div>
            </section>

            {/*********** Review Section ***********/}
            <section className="review-section">
                <div className="review-container">
                    <div className='text-center'>
                        <PR.Image src={userAvator} alt="User" className='user-avator'/>
                        <h2>“{branding?.BRAND0062}<span>{branding?.BRAND0063}</span> {branding?.BRAND0064} <span>{branding?.BRAND0065}</span> {branding?.BRAND0066}”</h2>
                        <p className='text-right'>- {branding?.BRAND0067}</p>
                    </div>
                </div>
            </section>

            {/*********** Our Values Section ***********/}
            <section className="our-values-section">
                <div className='text-center'>
                    <h2>{branding?.BRAND0068}</h2>
                </div>
                <div className="card">
                    <PR.Accordion activeIndex={0}>
                        <PR.AccordionTab header={branding?.BRAND0069}>
                            <p className="m-0">
                                {branding?.BRAND0070}
                            </p>
                        </PR.AccordionTab>
                        <PR.AccordionTab header={branding?.BRAND0071}>
                            <p className="m-0">
                                {branding?.BRAND0072}
                            </p>
                        </PR.AccordionTab>
                        <PR.AccordionTab header={branding?.BRAND0073}>
                            <p className="m-0">
                               {branding?.BRAND0074} 
                            </p>
                        </PR.AccordionTab>
                    </PR.Accordion>
                </div>
            </section>

            {/*********** Open Positions Section ***********/}
            <section id="positions" className="positions-section">
                <div className='text-left'>
                    <h2>{branding?.BRAND0075}</h2>
                </div>
          {jobList.map(item=>(
            <div className='job-list'>
                    <div className="grid">
                        <div className="col-12 lg:col-9">
                            <h3>{job[item?.title]}</h3>
                            <p>{job[item?.description]}</p>
                        </div>
                        <div className="col-12 lg:col-3 text-right">
                            <h3 className='location-h3'><i className="pi pi-map-marker"></i> {job[item?.location]}</h3>
                        </div>
                        <div className='col-12'>
                            <div className='flex align-items-center gap-5 custom-margin'>
                                <PR.Button label={branding?.BRAND0079} className="apply-button" />
                                <Link to="/" className='more-link'><i className="pi pi-download"></i> {branding?.BRAND0080}</Link>
                            </div>
                        </div>
                    </div>
                </div>
          ))   }

            </section>

            {/*********** Our Values Section ***********/}
            <section className="location-map-section">
                <PR.Image src={map} alt="User" className='user-avator'/>
                <div className='our-location'>
                    <h3>Cambridge, UK</h3>
                    <h3>Prague, CR</h3>
                    <h3>Chennai, IN</h3>
                    <h3>Vizag, IN</h3>
                </div>
            </section>

            {/*********** Meet the team Section ***********/}
            <section className="team-section">
                <div className='text-center'>
                    <h2>{branding?.BRAND0081}</h2>
                </div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-4">
                        <PR.Image src={teamMumber1} alt="User" className='user-avator'/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4">
                        <PR.Image src={teamMumber2} alt="User" className='user-avator'/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4">
                        <PR.Image src={teamMumber3} alt="User" className='user-avator'/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4">
                        <PR.Image src={teamMumber4} alt="User" className='user-avator'/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4">
                        <PR.Image src={teamMumber5} alt="User" className='user-avator'/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4">
                        <PR.Image src={teamMumber6} alt="User" className='user-avator'/>
                    </div>
                </div>
            </section>
        </Layout>
    </>
  );
};

export default JoinOurTeam;