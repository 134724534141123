import './i18n';
import React from 'react';
// **** Main CSS **** //
import './assets/scss/main.scss';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home/Home'
import JoinOurTeam from './pages/join-our-team/JoinOurTeam';
import GetInTouch from './pages/get-in-touch';
import Questions from './pages/questions';
import Partner from './pages/get-in-touch/partner';
import { QueryClient, QueryClientProvider } from 'react-query';
import store from '../src/store';
import { Provider } from 'react-redux';
import RequestCaseStudy from './pages/request-case-study';
import PageScrollToTop from './components/layout/ScrollToTop';

function App() {
  const queryClient = new QueryClient()
  return (
    <Provider store={store} >
    <QueryClientProvider client={queryClient}>
        <Router>
        <PageScrollToTop/>
        <Routes>
          <Route path='/' element={ <Home /> } />
          <Route path='/join-our-team' element={ <JoinOurTeam /> } />
          <Route path='/get-in-touch' element={ <GetInTouch /> } />
          <Route path='/partner' element={ <Partner /> } />
          <Route path='/questions' element={ <Questions /> } />
          <Route path='/request-case-study' element={ <RequestCaseStudy /> } />
          <Route path='*' element={ <Navigate to={'/'} />} />
        </Routes>
      </Router>
    </QueryClientProvider>
</Provider>
  );
}

export default App;