import React, { useState, useEffect } from 'react';

const FadeText = ({ texts, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const fadeInterval = setInterval(() => {
      setVisible(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setVisible(true);
      }, 1000);
    }, interval);
    return () => clearInterval(fadeInterval);
  }, [texts.length, interval]);

  return (
    <div className={`fade-text ${visible ? 'visible' : ''}`}>
      <span>{texts[currentIndex]}</span>
    </div>
  );
};

export default FadeText;