import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const CarouselContainer = styled.div`
  position: relative;
  height: 1000px;
  overflow: hidden;
`;

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transform: translateY(${props => props.translateY}px);
  transition: transform 0.5s ease-in-out;
`;

const CarouselItem = styled.div`
  font-size: 53px;
  font-weight: 400;
  line-height: 55px;
  letter-spacing: -2.659px;
  margin: 28px 0px;
`;

const VerticalCarousel = ({ items, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);

  // Duplicate the items array to create an infinite loop effect
  const extendedItems = [...items, ...items];

  useEffect(() => {
    const autoScroll = setInterval(() => {
      setCurrentIndex(prevIndex => prevIndex + 1);
    }, interval);

    return () => clearInterval(autoScroll);
  }, [interval]);

  useEffect(() => {
    const totalItems = items.length;

    if (currentIndex >= totalItems) {
      // Reset index after the first set is done
      setTimeout(() => {
        carouselRef.current.style.transition = 'none'; // Disable transition for a smooth jump
        setCurrentIndex(0); // Reset to the beginning of the original set
        setTimeout(() => {
          carouselRef.current.style.transition = 'transform 0.5s ease-in-out'; // Re-enable transition
        }, 50);
      }, 500); // Match the timeout with the transition duration
    }
  }, [currentIndex, items.length]);

  return (
    <div className='carousel-wrapper'>
      <CarouselContainer>
        <CarouselWrapper ref={carouselRef} translateY={-currentIndex * 100}>
          {extendedItems.map((item, index) => (
            <CarouselItem key={index} className='list'>
              {item.content}
            </CarouselItem>
          ))}
        </CarouselWrapper>
      </CarouselContainer>
    </div>
  );
};

export default VerticalCarousel;
